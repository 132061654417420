/* eslint-disable import/no-anonymous-default-export */
import { SHOW_LOADER, HIDE_LOADER } from "../actions/loading";

const data = false;

export default (state = data, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return true;
    case HIDE_LOADER:
      return false;
    default:
      return false;
  }
};
