/* eslint-disable import/no-anonymous-default-export */
import { LOGIN } from "../actions/auth";

const data = null;

export default (state = data, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...data, ...action.data };

    default:
      return state;
  }
};
