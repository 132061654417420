import React, { Component, createRef, Suspense } from "react";
import localStorage from "./utils/localStorage";
import { UserContext } from "./contexts";
import LoaderError, { ErrorBoundary } from "../Components/Common/loaderError";
import { SnackbarProvider } from "notistack";
import User from "./user";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import api from "../App/api";
const AppRoot = React.lazy(() => import("../Components/appRoot"));

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      fetching: false,
      success: true,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { success: false };
  }

  logout = () => {
    localStorage.logout();
    api.deleteSession();
    this.setState({ user: undefined, fetching: false, success: true });
  };

  login = (data) => {
    let user = data;
    if (!!user.token) localStorage.authToken = user.token;
    localStorage.user = JSON.stringify(user);
    this.setState({
      user: user,
      fetching: false,
      success: true,
    });
  };

  render() {
    const { user, fetching } = this.state;
    if (fetching) return <LoaderError className="min-vh-100" />;
    return (
      <UserContext.Provider
        value={{
          user: new User(user),
          login: this.login,
          logout: this.logout,
        }}
      >
        <AppRoot />
      </UserContext.Provider>
    );
  }

  componentDidMount() {
    if (!localStorage.isLoggedIn) return;
    this.setState({
      user: localStorage.user ? JSON.parse(localStorage.user) : {},
      fetching: false,
      success: true,
    });
  }
}

const App = (props) => {
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.handleCloseSnack(key);
  };

  return (
    <ErrorBoundary className="min-vh-100">
      <Suspense fallback={<LoaderError className="min-vh-100" />}>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          maxSnack={3}
          action={(key) => (
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={onClickDismiss(key)}
            >
              <HighlightOffIcon />
            </IconButton>
          )}
        >
          <Config {...props} />
        </SnackbarProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
