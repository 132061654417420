import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import reducers from "./rootReducers";
import ReduxThunk from "redux-thunk";
import Config from "./App/index";
import { GoogleOAuthProvider } from "@react-oauth/google";

const store = reducers && createStore(reducers, applyMiddleware(ReduxThunk));

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
          <GoogleOAuthProvider clientId="278932309256-9p52rgqvran7v54s48cpiocn7f7gqlq2.apps.googleusercontent.com">
            <Config />
          </GoogleOAuthProvider>
        </Provider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};
export default App;
