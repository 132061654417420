import { combineReducers } from "redux";
import auth from "./store/reducers/auth";
import loading from "./store/reducers/loading";

export const LOGOUT_ACTION = "LOGOUT";

const appReducer = combineReducers({
  auth: auth,
  loading: loading,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT_ACTION) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
